import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://b3cfcc72d00637196118bd93eeb135b4@o562906.ingest.us.sentry.io/4506882226323456",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: "rsickenberg-me@1.1.34",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});